import Api from "@/services/Api"
import { jsonToGraphQLQuery } from "json-to-graphql-query"

const URL_API = "graphql"

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async initialChatLender(dealId) {
    const objectForm = {
      query: {
        chatLender: {
          __args: {
            deal_id: parseInt(dealId),
          },
          name: true,
          room_id: true,
          chat_response_time: true,
          is_broker_subscribed: true,
          messages: {
            id: true,
            role: true,
            time: true,
            message: true,
            seen: true,
            forbidden_msg: true,
          },
        },
      },
    }

    const query = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async initialChatBroker(dealId) {
    const objectForm = {
      query: {
        chatBroker: {
          __args: {
            deal_id: parseInt(dealId),
          },
          rooms: {
            chat_response_time: true,
            room_id: true,
            name: true,
            messages: {
              id: true,
              role: true,
              time: true,
              message: true,
              seen: true,
              forbidden_msg: true,
            },
          },
        },
      },
    }

    const query = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendMessageLender(obj) {
    const objectForm = {
      mutation: {
        chatSendMessageLender: {
          __args: {
            input: obj,
          },
          chat_id: true,
          room_id: true,
          chat: {
            id: true,
            role: true,
            time: true,
            message: true,
            seen: true,
            forbidden_msg: true,
          },
        },
      },
    }

    const query = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendMessageBroker(obj) {
    const objectForm = {
      mutation: {
        chatSendMessageBroker: {
          __args: {
            input: obj,
          },
          chat_id: true,
          room_id: true,
          chat: {
            id: true,
            role: true,
            time: true,
            message: true,
            seen: true,
            forbidden_msg: true,
          },
        },
      },
    }

    const query = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  seenMessages(obj) {
    const objectForm = {
      mutation: {
        chatSeenMsg: {
          __args: {
            input: {
              seen: obj.arr,
            },
          },
          status: true,
          message: true,
        },
      },
    }

    const query = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return Api.post(URL_API, query)
  },

  async getZoomUrl(options) {
    const objectForm = {
      query: {
        chatVideoCall: {
          __args: {
            deal_id: options.deal_id,
          },
          start_url: true,
          join_url: true,
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async getChatRooms(options: {
    page: number
    perPage: number
    deal_id?: number
  }) {
    const objectForm = {
      query: {
        chatRooms: {
          __args: {
            page: options.page,
            per_page: options.perPage,
            deal_id: options.deal_id,
          },
          items: {
            name: true,
            chat_response_time: true,
            is_broker_subscribed: true,
            is_quote_exist: true,
            is_quote_accepted: true,
            quote_id: true,
            room_id: true,
            chat_id: true,
            deal_id: true,
            lender_id: true,
            avatar: true,
            company: true,
            location: true,
            messages: {
              id: true,
              name: true,
              avatar: true,
              role: true,
              message: true,
              time: true,
              seen: true,
              forbidden_msg: true,
            },
          },
          paginateInfo: {
            count: true,
            currentPage: true,
            firstItem: true,
            hasMorePages: true,
            lastItem: true,
            lastPage: true,
            perPage: true,
            total: true,
          },
        },
      },
    }

    if (!options.deal_id) {
      delete objectForm.query.chatRooms.__args.deal_id
    }

    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },


  async getChatRoomMessages(options: {
    room_id: number
    page: number
    perPage: number
  }) {
    const objectForm = {
      query: {
        chatRoomMessages: {
          __args: {
            room_id: options.room_id,
            page: options.page,
            per_page: options.perPage,
          },
          room: {
            name: true,
            avatar: true,
            company: true,
            location: true,
            deal_id: true,
            chat_response_time: true,
            room_id: true,
            chat_id: true,
            lender_id: true,
            quote_id: true,
            is_quote_exist: true,
            is_quote_accepted: true,
            is_broker_subscribed: true,
          },
          items: {
            id: true,
            name: true,
            avatar: true,
            role: true,
            message: true,
            time: true,
            seen: true,
            forbidden_msg: true,
          },
          paginateInfo: {
            count: true,
            currentPage: true,
            firstItem: true,
            hasMorePages: true,
            lastItem: true,
            lastPage: true,
            perPage: true,
            total: true,
          },
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async getUnseenMessages() {
    const objectForm = {
      query: {
        unseenMessages: {
          count: true,
          rooms: {
            room_id: true,
            messages_count: true,
          },
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async getDealLastUnseenRoom(obj: { deal_id: number, lender_id: number, quote: boolean }) {
    const objectForm = {
      query: {
        lastUnseenRoom: {
          __args: {
            deal_id: obj.deal_id,
            lender_id: obj.lender_id,
            quote: obj.quote,
          },
          id: true,
          broker_id: true,
          name: true,
          is_broker_subscribed: true,
          chat_response_time: true,
          company: true,
          is_quote_exist: true,
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },
}
