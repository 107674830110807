export default {
  IS_CHAT_OPENED(state, data) {
    state.lenderOpenClosedChat = data
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  SET_CHAT(state, data) {
    if (data) {
      state.chat = data
    } else {
      state.chat = {
        name: "",
        room_id: 0,
        messages: [],
      }
    }
  },
  RESET_CHAT(state) {
    state.chat = null
  },
  SET_PLAY_NOTIFICATION_SOUND(state, data) {
    state.chat.playNotificationSound = data
  },
  SET_CHAT_NEW_MSG_COUNT(state, data) {
    if (state.chat) {
      let num = 0
      if (data.length > 0) {
        data.forEach((room) => {
          room.messages.forEach((msg) => {
            if (msg.role === "lender") {
              if (!msg.seen) num += 1
            }
          })
        })
        state.chatCountNewMsg = num
      } else {
        state.chat.rooms.forEach((room) => {
          room.messages.forEach((msg) => {
            if (msg.role === "lender") {
              if (!msg.seen) num += 1
            }
          })
        })
        state.chatCountNewMsg = num
      }
    }
  },
  SET_NEW_MESSAGE_LENDER_AFTER_FAKE(state, data) {
    if (state.chat) {
      if (state.chat.room_id === 0) {
        state.chat.room_id = data.data.room_id
      }
      const indexMsg = state.chat.messages.findIndex((msg) => msg.id === data.fake)
      if (indexMsg >= 0) {
        state.chat.messages.splice(indexMsg, 1)
        state.chat.messages.push(data.data.chat)
      }
    }
  },
  SET_NEW_MESSAGE_FROM_PUSHER(state, data) {
    if (state.chat && state.chat.rooms?.items) {
      const roomID = data.room_id
      const chat = data.chat

      state.chat.scrollToBottom = state.chat.scrollToBottom + 1
      data.chat.newMessage = data.chat.message

      state.chat.rooms.items = state.chat.rooms.items.reduce((acc, room) => {
        if (room.room_id === roomID) {
          acc.unshift({
            ...room,
            messages: [
              {
                ...chat,
                seen: state.chat.selectedRoom.room_id === roomID,
              },
              ...room.messages,
            ],
          })
        } else {
          acc.push(room)
        }
        return acc
      }, [])

      if (state.chat.messages?.room && state.chat.messages.room.room_id === roomID) {
        state.chat.messages.items.push({
          ...chat,
          seen: true,
        })
      }
    }
  },
  SET_NEW_MESSAGE_BROKER_AFTER_FAKE(state, data) {
    if (state.chat) {
      const index = state.chat.rooms.findIndex((room) => room.room_id === data.data.room_id)
      if (index >= 0) {
        const indexMsg = state.chat.rooms[index].messages.findIndex((msg) => msg.id === data.fake)
        if (indexMsg >= 0) {
          state.chat.rooms[index].messages.splice(indexMsg, 1)
          state.chat.rooms[index].messages.push(data.data.chat)
        }
      }
    }
  },
  SET_NEW_FIRST_MESSAGE_BROKER(state, data) {
    if (state.chat) {
      state.chat.rooms.push(data)
    } else {
      state.chat = {
        rooms: [data],
      }
    }
  },
  CHANGE_SEEN_MESSAGES(state, obj) {
    state.chat.rooms[obj.index].messages.forEach((msg) => {
      if (obj.arr.includes(msg.id)) {
        msg.seen = true
      }
    })
  },
  CHANGE_SEEN_MESSAGES_LENDER(state, arr) {
    state.chat.messages.forEach((msg) => {
      if (arr.includes(msg.id)) {
        msg.seen = true
      }
    })
  },
  SET_CURRENT_CHAT(state, data) {
    state.currentChatId = data
  },
  SET_BROKER_FIRST_CHAT(state, obj) {
    state.chat ? state.chat.rooms.push(obj) : (state.chat.rooms = [obj])
  },

  SET_ROOMS(state, rooms) {
    if (state.chat?.rooms?.items) {
      state.chat.rooms = {
        items: [...state.chat.rooms.items, ...rooms.items],
        paginateInfo: rooms.paginateInfo,
      }
    } else {
      state.chat.rooms = rooms
    }
  },

  SET_NEW_MESSAGE(state, message) {
    state.chat.newMessage = message
  },

  RESET_ROOMS(state) {
    state.chat.rooms = []
  },

  RESET_MESSAGES(state) {
    state.chat.messages = []
  },

  FINISH_FIRST_MESSAGE(state) {
    state.chat.finishFirstMessage = true
  },

  SET_ROOM_MESSAGES(state, messages) {
    state.chat.isMessagesLoading = false

    if (state.chat?.messages?.items) {
      state.chat.messages = {
        ...state.chat.messages,
        items: [...messages.items.reverse(), ...state.chat.messages.items],
        paginateInfo: state.chat.messages.paginateInfo,
      }
    } else {
      state.chat.messages = {
        ...messages,
        items: messages.items.reverse(),
      }
    }
  },

  IS_MESSAGES_LOADING(state, isMessagesLoading) {
    state.chat.isMessagesLoading = isMessagesLoading
  },

  DISABLE_SELECT(state) {
    state.chat.selectedRoom = {
      chat_id: null,
      room_id: null,
    }
  },

  SET_MESSAGE_ON_ROOM(state, messageData) {
    const roomID = state.chat?.messages?.room?.room_id
    let updatedRooms = state.chat.rooms.items.map((room) => {
      if (room.room_id === roomID) {
        return {
          ...room,
          messages: room.messages.map((msg, index) => {
            if (index === 0) {
              return { ...msg, ...messageData, seen: true }
            }
            return msg
          }),
        }
      }
      return room
    })

    // Move the room with roomID to the first position
    updatedRooms = updatedRooms.filter((room) => room.room_id !== roomID)
    const targetRoom = state.chat.rooms.items.find((room) => room.room_id === roomID)
    if (targetRoom) {
      updatedRooms.unshift(targetRoom)
    }

    state.chat.rooms.items = updatedRooms
    state.chat.messages.items.push({
      ...messageData,
      forbidden_msg: messageData.forbidden_msg,
      fake: messageData.fake,
    })
  },

  UPDATE_MESSAGE_ON_ROOM(state, messageData) {
    const roomID = state.chat?.messages?.room?.room_id
    const updatedRooms = state?.chat?.rooms?.items?.map((room) => {
      if (room.room_id === roomID) {
        return {
          ...room,
          messages: [{ ...messageData, seen: true }, ...room.messages],
        }
      }
      return room
    })

    const updatedMessages = state.chat?.messages?.items?.map((msg) => {
      if (msg.id === messageData.fakeId) {
        return { ...msg, ...messageData, fake: false }
      }
      return msg
    })

    state.chat.rooms.items = updatedRooms
    state.chat.messages.items = updatedMessages
  },

  UPDATE_FAKE_MESSAGE_ON_ROOM(state, { fakeId, data }) {
    const updatedMessages = state?.chat?.messages?.items?.map((msg) => {
      if (msg.id === fakeId) {
        return { 
          ...msg,
          id: data.chat.id,
          fake: false,
          forbidden_msg: data.chat.forbidden_msg,
        }
      }
      return msg
    })

    state.chat.messages.items = updatedMessages
  },

  SET_UNSEEN_MESSAGES(state, unseenMessages) {
    state.chat.unseenMessages = unseenMessages
  },

  SET_LAST_UNSEEN_ROOM(state, data) {
    if (data) {
      state.chat.dealLastUnseenMessage = data
    }
  },

  INCREMENT_UNSEEN_MESSAGES(state, messageData) {
    const isSameRoom =
      messageData.room_id === state.chat?.selectedRoom?.room_id &&
      (window.location.pathname === "/broker-messages" || window.location.pathname === "/lender-messages")

    if (isSameRoom) {
      state.chat.newMessage = messageData.chat.message
      return
    }

    const unseenRoom = state.chat?.unseenMessages?.rooms.find((room) => room.room_id === messageData.room_id)

    if (unseenRoom) {
      unseenRoom.messages_count++
    } else {
      state.chat.unseenMessages.rooms.push({
        room_id: messageData.room_id,
        messages_count: 1,
      })
    }

    state.chat.unseenMessages.count++
  },

  UNSEEN_MESSAGES_DECREMENT(state, roomId) {
    const unseenRoom = state.chat.unseenMessages.rooms.find((room) => room.room_id === roomId)

    if (!unseenRoom) {
      return
    }

    state.chat.rooms.items = state.chat.rooms.items.map((room) => {
      if (room.room_id === roomId) {
        room.messages = room.messages.map((msg) => ({ ...msg, seen: true }))
      }
      return room
    })

    state.chat.unseenMessages.count -= unseenRoom.messages_count
    state.chat.unseenMessages.rooms = state.chat.unseenMessages.rooms.filter((room) => room.room_id !== roomId)
  },

  SELECT_ROOM(state, selectedRoom) {
    const storage = localStorage.getItem("auth") || null
    if (storage) {
      const obj = JSON.parse(storage)
      obj.selected_room = selectedRoom

      localStorage.setItem("auth", JSON.stringify(obj))
    }

    state.chat.selectedRoom = selectedRoom
  },
}
